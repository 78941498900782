import { booleanAttribute, ChangeDetectionStrategy, Component, input, model, output } from '@angular/core';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { Tab, TabComponent } from './tab/tab.component';

@Component({
  selector: 'coach-tabs',
  host: { '[class]': '[wrap()]', '[class.vertical]': 'vertical()', '[class.chips]': 'chips()' },
  imports: [TabComponent, TypographyDirective],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  tabs = input.required<Tab[]>();
  activeTabId = model<string | number>();
  title = input<string>();
  vertical = input(false, { transform: booleanAttribute });
  square = input(false, { transform: booleanAttribute });
  wrap = input(false, { transform: booleanAttribute });
  chips = input(false, { transform: booleanAttribute });

  clicked = output<Tab>();

  tabClicked(tab: Tab) {
    this.activeTabId.set(tab.id);
    this.clicked.emit(tab);
  }
}
